import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import { Analytics } from "@vercel/analytics/react";
import { FaPaw, FaSnowflake, FaTimes } from 'react-icons/fa';
import './App.css';
import Loading from './components/Loading';

// Lazy-load components for better performance
const Experience = lazy(() => import('./components/Experience'));
const ProjectCards = lazy(() => import('./components/Projects'));
const Skills = lazy(() => import('./components/Skills'));
const Resume = lazy(() => import('./components/Resume'));
const ParticlesBackground = lazy(() => import('./components/ParticlesBackground'));
const Contact = lazy(() => import('./components/Contact'));
const FloatingImages = lazy(() => import('./components/FloatingImages'));
const ContributionGraph = lazy(() => import('./components/ContributionGraph'));

// SEO component for dynamic page titles and metadata
function SEO({ title, description }) {
  useEffect(() => {
    document.title = title || 'Daksh Gandhi - Full Stack Developer | UIUC Graduate';
    
    // Update meta description if provided
    if (description) {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
    }
  }, [title, description]);

  return null;
}

// Component to handle route changes
function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}

function AppContent() {
  const location = useLocation();
  
  // Set SEO metadata based on current route
  let seoTitle = 'Daksh Gandhi - Full Stack Developer | UIUC Graduate';
  let seoDescription = 'Daksh Gandhi is a full stack developer with a Master\'s from UIUC, specializing in cloud computing, distributed systems, and software automation.';
  
  if (location.pathname === '/projects') {
    seoTitle = 'Projects | Daksh Gandhi - Full Stack Developer';
    seoDescription = 'Explore my portfolio of projects in web development, cloud computing, and software automation.';
  } else if (location.pathname === '/experience') {
    seoTitle = 'Experience | Daksh Gandhi - Full Stack Developer';
    seoDescription = 'Learn about my professional experience and skills in software development and cloud computing.';
  } else if (location.pathname === '/resume') {
    seoTitle = 'Resume | Daksh Gandhi - Full Stack Developer';
    seoDescription = 'View my professional resume showcasing my skills, education, and work experience.';
  }
  
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <ScrollToTop />
    </>
  );
}

function App() {
  const [bgMode, setBgMode] = useState(() => {
    const saved = localStorage.getItem('bgMode');
    return saved !== null ? JSON.parse(saved) : 1; 
  });

  const handleToggle = () => {
    // Cycle through 0 -> 1 -> 2 -> 0...
    setBgMode(prev => {
      const next = (prev + 1) % 3;
      localStorage.setItem('bgMode', JSON.stringify(next));
      return next;
    });
  };

  let backgroundElement = null;
  if (bgMode === 1) {
    backgroundElement = (
      <Suspense fallback={<div className="background-placeholder"></div>}>
        <ParticlesBackground style={{ position: "fixed", zIndex: -1 }} />
      </Suspense>
    );
  } else if (bgMode === 2) {
    backgroundElement = (
      <Suspense fallback={<div className="background-placeholder"></div>}>
        <FloatingImages style={{ position: "fixed", zIndex: -1 }} />
      </Suspense>
    );
  }

  let toggleIcon = null;
  if (bgMode === 0) {
    toggleIcon = <FaSnowflake />;
  } else if (bgMode === 1) {
    toggleIcon = <FaPaw />;
  } else {
    toggleIcon = <FaTimes />;
  }

  return (
    <Router>
      <Analytics />
      <AppContent />
      {backgroundElement}
      <Navbar />
      <button className="floating-button" onClick={handleToggle} title="Toggle Background">
        {toggleIcon}
      </button>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Suspense fallback={<Loading />}>
                  <ContributionGraph username="dakshg3" />
                </Suspense>
                <Suspense fallback={<Loading />}>
                  <Skills />
                </Suspense>
                <Suspense fallback={<Loading />}>
                  <Contact />
                </Suspense>
              </>
            }
          />
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects" element={<ProjectCards />} />
          <Route path="/resume" element={<Resume />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
